import DatePicker from "react-datepicker";
import { useState } from "react";
import './calendar.css';

interface ICustomDatePicker {
  selected: Date | null,
  onChange: (date: any, event?: any) => void;
  // selectsMultiple: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  showMonthDropdown: boolean;
  showYearDropdown: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDatePicker = ({
  selected,
  onChange,
  selectsStart,
  startDate,
  endDate,
  showMonthDropdown,
  showYearDropdown,
  minDate,
  maxDate,
}: ICustomDatePicker) => {
    return (
      <>
        <DatePicker
          selected={selected}              // The currently selected date
          onChange={onChange}              // Function to call when date changes
          selectsStart={selectsStart}      // Determines if it selects the start date (for ranges)
          startDate={startDate}            // Start date (if using date range)
          endDate={endDate}                // End date (if using date range)
          showMonthDropdown={showMonthDropdown} // Whether to show the month dropdown
          showYearDropdown={showYearDropdown}   // Whether to show the year dropdown
          minDate={minDate}                // Minimum selectable date
        maxDate={maxDate}                   // Maximum selectable date
          dropdownMode="select"
        />
      </>
    );
  };

  export default CustomDatePicker